














































































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import AddressDTO from "@/dto/AddressDTO";
import {STATE_LIST} from "@/constants/ProfileConstants";
import AutoComplete from "@/components/common/AutoComplete.vue";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import CompanySearch from "@/components/common/CompanySearch.vue";
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalDateTime from "@/components/common/PortalDateTime.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import SelectOption from "@/components/common/SelectOption";

@Component({
	computed: {
		SelectOption() {
			return SelectOption
		},
		states(): Array<string> {
			return STATE_LIST;
		},
		AutoCompleteDTO() {
			return AutoCompleteDTO
		}
	},
	components: {BTPortalCollapse, SublimeSearch, PortalSelect, PortalDateTime, PersonSearch, CompanySearch, PortalInput, AutoComplete}
})
export default class PortalAddress extends Vue {
	private addressIsOpenMirror = false

	@Prop({default: false})
	private addressIsOpen!: boolean;

	@VModel({type: AddressDTO, default: () => new AddressDTO()})
	private address!: AddressDTO

	@Prop({default: "portal_address"})
	private id!: string;

	@Prop()
	private label!: string;

	@Prop({default: false})
	private isOpen!: boolean

	private isOpenMirror!: boolean;
  private tabIndex = 0;

	public created(): void {
		this.isOpenMirror = this.isOpen
		this.addressIsOpenMirror = this.addressIsOpen;
	}

	private applyChange() {
		this.$emit("input", this.address)
	}

	private applyNoResidential() {
		this.address.isResidential = false;
		this.$emit("input", this.address)
	}

	private applyResidential() {
		this.address.isResidential = true;
		this.$emit("input", this.address)
	}
}
