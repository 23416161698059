




























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import PersonSearch from "@/components/common/PersonSearch.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import {PersonSex, ResidencyStatus, SSNType, StateIDSubtype} from "@/constants/PersonConstants";
import {PublicRIDCContractorDTO} from "@/dto/request/ridc/PublicRIDCContractorDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import {namespace} from "vuex-class";
import PortalAddress from "@/components/profile/branches/PortalAddress.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {CitizenPassportDataDTO, EADDataDTO, GreenCardDataDTO, StateIDDataDTO} from "@/dto/request/rer/CompleteREREmployeePayload";
import RIDCService from "@/services/request/RIDCService";
import {RIDCContractorStatus} from "@/constants/request/RIDCConstants";
import CompleteRIDCContractorPayload from "@/dto/request/ridc/CompleteRIDCContractorPayload";
import { processError } from "@/utils/ComponentUtils";

const AppModule = namespace("App");

@Component({
  computed: {
    SSNType() {
      return SSNType
    },
    StateIDSubtype() {
      return StateIDSubtype
    },
    PersonSex() {
      return PersonSex
    },
    ResidencyStatus() {
      return ResidencyStatus
    }
  },
  components: {
    PortalCheckbox,
    PortalAddress,
    PortalCollapse,
    PortalInput,
    SingleFileHolder,
    PortalDate,
    PersonSearch
  }
})
export default class ContractorRIDC extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  contractor!: PublicRIDCContractorDTO;

  payload = new CompleteRIDCContractorPayload();

  createPerson = false;

  selectedPerson: PersonDTO | null = null;

  mounted() {
    this.initPayload();
  }

  initPayload() {
    switch (this.contractor.residencyStatus) {
      case ResidencyStatus.CITIZEN:
        this.payload.passport = new CitizenPassportDataDTO()
        break;
      case ResidencyStatus.PERMANENT_RESIDENT:
        this.payload.greenCard = new GreenCardDataDTO()
        break;
      case ResidencyStatus.NON_CITIZEN:
        this.payload.ead = new EADDataDTO();
        break;
    }
    this.payload.stateID = new StateIDDataDTO();
  }

  onPersonSelected(person: PersonDTO) {
    this.payload.personId = person.id;
  }

  save() {
    this.startLoading();
    RIDCService.completeContractorRegistration(String(this.contractor.id), this.payload).then(
        ok => {
          this.contractor.status = RIDCContractorStatus.COMPLETED;
          this.stopLoading();
        },
        err => {
          processError(err, this);
          this.stopLoading();
        }
    )
  }

}
