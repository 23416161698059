import {PersonSex, ResidencyStatus, SSNType, StateIDSubtype} from "@/constants/PersonConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AddressDTO from "@/dto/AddressDTO";

export default class CompleteREREmployeePayload {

    id: number | null = null;
    residencyStatus: ResidencyStatus | null = null;
    personId: number | null = null;
    employeeId: number | null = null;
    employmentStart: Date | null = null;
    firstName: string | null = null;
    lastName: string | null = null;
    sex: PersonSex | null = null;
    countryOfBirth: string | null = null;
    dateOfBirth: string | null = null;
    nationality: string | null = null;
    ead?: EADDataDTO | null = null;
    greenCard?: GreenCardDataDTO | null = null;
    passport?: CitizenPassportDataDTO | null = null;
    ssn = new SSNCardDataDTO();
    stateID = new StateIDDataDTO();

    constructor(init?: Partial<CompleteREREmployeePayload>) {
        Object.assign(this, init);
    }

}

export class EADDataDTO {

    uscisCode: string | null = null;
    category: string | null = null;
    cardId: string | null = null;
    termsAndConditions: string | null = null;
    validFrom: string | null = null;
    cardExpires: string | null = null;
    otherNotes: string | null = null;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<EADDataDTO>) {
        Object.assign(this, init);
    }
}

export class GreenCardDataDTO {

    uscisCode: string | null = null;
    category: string | null = null;
    residentSince: string | null = null;
    cardExpires: string | null = null;
    file: FileMetaDTO | null = null;

    constructor(init?: Partial<GreenCardDataDTO>) {
        Object.assign(this, init);
    }
}

export class CitizenPassportDataDTO {

    documentNumber: string | null = null;
    issueDate: string | null = null;
    expirationDate: string | null = null;
    file: FileMetaDTO | null = null;

    constructor(init?: Partial<CitizenPassportDataDTO>) {
        Object.assign(this, init);
    }
}

export class SSNCardDataDTO {

    ssn: string | null = null;
    file: FileMetaDTO | null = null;
    type: SSNType | null = null;

    constructor(init?: Partial<SSNCardDataDTO>) {
        Object.assign(this, init);
    }
}

export class StateIDDataDTO {

    state: string | null = null;
    subtype: StateIDSubtype | null = null;
    documentNumber: string | null = null;
    address = new AddressDTO();
    issueDate: string | null = null;
    expirationDate: string | null = null;
    otherNotes: string | null = null;
    front: FileMetaDTO | null = null;
    back: FileMetaDTO | null = null;

    constructor(init?: Partial<StateIDDataDTO>) {
        Object.assign(this, init);
    }
}
